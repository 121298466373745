/* ******************* SideBar ******************* */
.sidebar {
    padding-top: 30px;
    position: relative;
    max-height: calc(100vh - 15px);
    height: calc(100vh - 15px);
    .main-menu {
        margin-top: 20px;
        height: calc(100vh - 430px);
        overflow: auto;
        flex-wrap: nowrap;
        display: flex;
        margin-bottom: 5px;
        padding-left: 8px;
    }
    .addOrder-box {
        position: absolute;
        bottom: 25px;
        width: 100%;
        background-color: #faf8fb;
        border-radius: 15px;
        padding: 19px 16px 5px 16px;
        text-align: center;
        img {
            width: 100%;
        }
        button,
        a {
            width: 100%;
            padding: 3px 4px;
            font-size: 14px;
            line-height: 26px;
            text-align: right;
            img {
                width: 42px;
                height: 42px;
                margin-left: 8px;
            }
        }
    }
    .sidebar-dropup {
        display: none;
    }
}

.sidebar-header {
    display: flex;
    align-items: center;
    a {
        color: #121432;
    }
    .buffet-logo{
        display: flex;
        align-items: center;
    }
    img {
        height: 32px;
        padding-left: 15px;
    }
    span {
        font-size: 20px;
        font-weight: 600;
    }
}
.sidebar .main-menu > a.active,
.sidebar .main-menu > .show.dropdown > button.btn-primary {
    color: #121432;
    background-color: #f4e3eb;
}

.sidebar .main-menu > a,
.sidebar .main-menu > .dropdown > button.btn-primary {
    color: inherit;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-decoration: none;
    // height: 48px;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    color: rgba(18, 20, 50, 0.5);
    border-radius: 15px;
    width: 100%;
    text-align: right;
    border: 0;
    padding: 3px;
    background-color: transparent;
    margin-bottom: 16px;
}
.dropdown-toggle::after {
    display: none;
}
.sidebar .main-menu > a img {
    width: 42px;
    height: 42px;
    object-fit: contain;
}
.sidebar .main-menu > a img,
.sidebar .main-menu > button img {
    margin-left: 12px;
}

.sidebar .main-menu .dropdown:focus {
    outline: 0;
}

.sidebar .main-menu .dropdown-menu {
    position: unset;
    display: block;
    height: 0;
    overflow: hidden;
    transition: 0.6s;
    opacity: 0;
    padding: 0;
    transform: translateY(-10px);
    background-color: rgba(244, 227, 235, 0.15);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    text-align: right;
    border: 0;
    color: rgba(18, 20, 50, 0.5);
    margin-bottom: 16px;
}
.sidebar .main-menu .dropdown {
    width: 100%;
    button{
        img{
            margin-left: 8px;
        }
    }
}
.sidebar .main-menu .dropdown-menu.show {
    height: auto;
    position: static !important;
    opacity: 1;
    transform: translateY(-14px) !important ;
    padding: 30px 16px 10px 16px;
}

.sidebar .main-menu .dropdown-menu a {
    color: inherit;
    font-size: 14px;
    line-height: 26px;
    padding: 8px 33px 8px 0;
    margin-bottom: 11px;
    display: inline-block;
    transition: 0.6s;
    width: 100%;
}

.sidebar .main-menu .dropdown-menu a.active ,.sidebar .main-menu .dropdown-menu a:hover,.sidebar .main-menu .dropdown-menu li:hover a{
    color: rgb(18, 20, 50);
    padding-right: 41px;
}

.title-control {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: inherit;
    margin-bottom: 16px;
}

.title-control img {
    width: 25px;
    height: 25px;
    margin-left: 8px;
}
/* ******************* SideBar ******************* */

/* ******************* topbar ******************* */
.topbar {
    padding: 24px 15px;
    display: flex;
    justify-content: flex-end;
    .btn {
        img {
            object-fit: cover;
        }
    }
    img {
        object-fit: contain;
    }
    .cart-counter ,.notification-counter{
        background-color: #fd6b6b;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        display: flex;
        font-size: 8px;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    
    .notifications-dropdown {
        border-radius: 0 15px 15px 15px;
        background-color: #f6f7fb;
        padding: 16px;
        border: 0;
        min-width: 400px;
        text-align: center;
        ul {
            margin: 0;
            padding: 0;
            max-height: 400px;
            overflow: auto;
            margin-bottom: 16px;
            li {
                list-style: none;
                background-color: #eef1f9;
                padding: 16px;
                border-radius: 15px;
                margin-bottom: 8px;
                text-align: right;
                margin-left: 8px;
                margin-right: 8px;
                animation: enterAnimation 0.6s ease-in-out;
                h4 {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
    .dropdown.notification-popup {
        .notification-counter {
            position: absolute;
            right: 5px;
            top: -5px;
        }
    }
    .dropdown.cart-popup,.dropdown.notification-popup {
        .cart-counter {
            position: absolute;
            right: 5px;
            top: -5px;
        }
        .dropdown-menu {
            background-color: #f6f7fb;
            padding: 24px 0;
            border: 0;
            border-radius: 0 15px 15px 15px;
            width: 470px;
            transform: translate(15px, 49px);
            display: block;
            .cart-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 16px;
                margin-bottom: 16px;
                .cart-btn {
                    font-size: 17px;
                    width: 32px;
                    height: 32px;
                    margin-right: 16px;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                }
            }
            .cart-popup-btns {
                display: flex;
                padding-top: 16px;
                border-top: 1px solid #ebedf3;
                margin-top: 16px;
                justify-content: center;
                align-items: center;
                .btn {
                    margin-left: 16px;
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .btn-icon {
        width: 47px;
        height: 47px;
        border-radius: 15px;
        overflow: hidden;
        margin-left: 15px;
        padding: 0;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f6f7fb;
        &:focus {
            border: 0;
            box-shadow: none;
        }
    }
    button.btn-topbar {
        margin-left: 15px;
        height: 47px;
        border-radius: 15px;
        overflow: hidden;
        padding: 0 24px;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f6f7fb;
        &:focus {
            border: 0;
            box-shadow: none;
        }
    }
    .dropdown.user-menu {
        .dropdown-menu {
            background-color: #f4e3eb;
            border-radius: 15px;
            padding: 0;
            position: relative;
            top: 10px !important;
            border: 0;
            box-shadow: none;
            span {
                margin-right: 8px;
            }
            .arrowUp {
                position: absolute;
                left: 10px;
                top: -10px;
                display: inline-block;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 15px solid #f4e3eb;
            }
            a {
                padding: 16px 24px;
                display: inline-block;
                border-radius: 15px;
                width: 100%;
                text-align: right;
                &:hover,
                &:focus {
                    color: #000;
                    background-color: transparent;
                }
            }
        }
        button {
            width: 47px;
            height: 47px;
            border-radius: 15px;
            overflow: hidden;
            padding: 0;
            border: 0;
            &:focus {
                border: 0;
                box-shadow: none;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 15px;
            }
        }
    }
}
.btn-userlogout {
    .dropdown-menu {
        background-color: #f4e3eb;
        border-radius: 15px;
        padding: 0;
        position: relative;
        top: 10px !important;
        border: 0;
        left: 15px !important;
        box-shadow: none;
        span {
            margin-right: 8px;
        }
        .arrowUp {
            position: absolute;
            right: 40px;
            top: -10px;
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #f4e3eb;
        }
        a {
            padding: 16px 24px;
            display: inline-block;
            border-radius: 15px;
            width: 100%;
            text-align: right;
            &:hover,
            &:focus {
                color: #000;
                background-color: transparent;
            }
        }
    }
}

@media (max-width: 1199px) {
    .title-control{
        font-size: 14px;
    }
.topbar {
    .btn-icon{
        margin-left: 0;
        margin-right: 15px;
    }
}
.btn-userlogout {
    .dropdown-menu{
        left: 0;
        .arrowUp{
            right: auto;
            left: 15px;
        }
    }
}
    .sidebar {
        .main-menu{
            height: calc(100vh - 350px);
        }
        position: relative;
        .sidebar-dropup {
            display: block;
            width: 0;
            height: 0;
            z-index: -1;
        }
        &.showMenu{
            .sidebar-dropup{
                width: 100vw;
                height: 100vh;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
    .sidebar .addOrder-box {
        position: static;
        width: 100%;
        background-color: transparent;
        display: block;
        border-radius: 0;
        padding: 20px 0;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        a.btn {
            width: 185px;
            float: right;
        }
        .dropdown {
            width: 47px;
            height: 47px;
            float: left;
            img {
                width: 47px;
                height: 47px;
                border-radius: 15px;
            }
            .dropdown-toggle::after {
                display: none;
            }
        }
        .main-menu {
            height: calc(100vh - 195px);
        }
    }
    .topbar.mv {
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
        .left,
        .right {
            display: flex;
        }
        .dropdown {
            .dropdown-menu {
                left: 50% !important;
                .arrowUp {
                    left: auto;
                    right: 10px;
                }
            }
        }
        .btn-menu {
            padding: 0;
            margin-left: 15px;
            outline: 0;
            font-size: 24px;
            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
    }
    .sidebar {
        position: fixed;
        z-index: 9;
        background: #fff;
        right: 0;
        top: 0;
        width: 300px;
        transition: 0.6s;
        transform: translateX(500px);
        box-shadow: 0px -20px 20px 5px rgba($color: #000000, $alpha: 0.2);
        padding: 30px 20px;
    }
    .showMenu {
        transform: translateX(0);
    }
    .app-content {
        padding-top: 110px;
        padding-right: 0;
        padding-left: 0;
    }
    .nopadding-m {
        padding: 0;
    }
    .cv {
        display: none !important;
    }

    .sidebar-header.mv {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 24px;
        .btn-close {
            padding: 0;
            background: transparent;
            font-size: 24px;
        }
    }
}
@media (min-width: 1200px) {
    .mv {
        display: none;
    }
}
/* ******************* topbar ******************* */
