.signin-page{
    background: url(../../assets/img/login_background.svg) no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;
    .alert{
    margin-top: 1rem;
    position: absolute;
    bottom: auto;
    top: 100%;
    right: 15px;
  }
}

.signin-heading{
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 44px;
    margin-top: 22px;
}

.signin-page .input-field{
    position: relative;
    margin-bottom: 24px;
  }
  .signin-page .input-field label{
    position: absolute;
    bottom: 10px;
    right: 0;
    z-index: -1;
    transform: translateY(5px);
    color: rgba(18, 20, 50, 0.5);
    font-size: 20px;
    transition: .4s;
    line-height: 27px;
  }
  .signin-page .input-field input:focus + label ,.signin-page .input-field input:valid + label {
    transform: translate(10px,-20px) scale(0.8);
    /* transform-origin: 100% 0; */
    color: rgba(18, 20, 50, 1);

  }
  .signin-page .input-field input:focus {
    outline: none;
  }
  .signin-page .input-field input{
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(18, 20, 50, 0.5);
    height: 49px;
    
  }
  .signin-page .input-field input:focus{
    border-bottom: 1px solid rgba(18, 20, 50, 1);

  }
  @media(max-width:1024px){
    .signin-header{
      width: 100%;
    }
    .login-img{
      margin-bottom: 30px;
    }
  }
  @media(max-width:480px){
    .signin-page{
      .btn{
        width: 100%;
      }
      .login-img{
        display: none
      }
    }
    .signin-header.cv{
      display: block !important;
    }
  }