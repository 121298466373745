.dashboard {
    .dashboard-welcome {
        position: relative;
        padding: 16px 32px;
        background-color: #faf8fb;
        border-radius: 15px;
        h2 {
            color: #384a71;
        }
        h1 {
            color: #dd99b9;
        }
        p {
            color: #8c96ad;
        }
        img {
            position: absolute;
            left: 32px;
            bottom: 0;
            height: 110%;
        }
    }
    .statistics {
        display: flex;
        flex-wrap: wrap;
        .statistics-box {
            display: flex;
            flex-wrap: wrap;
            flex-basis: calc(50% - 12px);
            margin-top: 32px;
            &:nth-child(2n + 1) {
                margin-left: 12px;
            }
            &:nth-child(2n) {
                margin-right: 12px;
            }
            .box {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex-basis: calc(50% - 12px);
                max-width: calc(50% - 12px);
                overflow: hidden;
                background-color: #faf8fb;
                color: #41425b;
                height: calc((357px / 2) - 12px);
                margin-bottom: 24px;
                border-radius: 15px;
                margin-left: 24px;
                padding: 32px;
                h3 {
                    font-size: 35px;
                    font-weight: 700;
                }
                h4 {
                    font-size: 14px;
                    font-weight: 400;
                }

                &:nth-child(2n) {
                    margin-left: 0;
                }
                &.blue {
                    background-color: #20c2ff;
                    color: #fff;
                }
                &.dark {
                    background-color: #5a62b3;
                    color: #fff;
                }
            }
            canvas {
                background-color: #20c2ff;
                border-radius: 15px;
                padding: 24px;
                max-width: 100%;
                max-height: 357px;
            }
            .statistics-panel {
                height: 456px;
                border-radius: 15px;
                background-color: #faf8fb;
                padding: 24px;
                width: 100%;
                overflow: hidden;
                ul {
                    padding: 0 0 0 8px;
                    
                    max-height: calc(456px - 80px);
                    overflow: auto;
                    margin: 0;
                }
                .panel-heading {
                    color: #000;
                    margin-bottom: 16px;
                    font-weight: 400;
                    display: flex;
                    height: 56px;
                    justify-content: space-between;
                    padding: 12px 16px;
                    align-items: center;
                    background-color: #dcdef4;
                    border-radius: 15px;
                    h3 {
                        margin: 0;
                        font-size: 16px;
                    }
                    button.btn {
                        background-color: #fff;
                        font-size: 14px;
                        line-height: 1.618;
                        color: #000;
                        padding: 2px 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 15px;
                        .panel-icon {
                            font-size: 14px;
                            line-height: 1;
                            color: #dcdce4;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin-right: 16px;
                            .active {
                                color: #8f91aa;
                            }
                        }
                    }
                }
                .order-item {
                    padding: 8px;
                    display: flex;
                    margin-bottom: 24px;
                    width: 100%;
                    border-radius: 15px;
                    align-items: center;
                    span,.right{
                        display: flex;
                        align-items: center;
                    }
                    >span{
                        text-align: center;
                    }
                    
                    .employee-rating{
                        background-color: #DCDEF4;
                        border-radius: 7px;
                        width: 68px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #121432;
                        font-size: 12px;
                        .icon{
                            color: #8F91AA;
                            margin-left: 8px;
                        }
                    }
                }
                &.panel-red {
                    .panel-heading {
                        background-color: #e6233e;
                        color: #fff;
                    }
                    .order-item {
                        background-color: #fff;
                    }
                    .order-number {
                        background-color: #ec6578;
                        color: #fff;
                        font-size: 16px;
                        width: 34px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 16px;
                        border-radius: 10px;
                    }
                }
                &.panel-danger {
                    .panel-heading {
                        background-color: #f4e3eb;
                        color: #000;
                    }
                    .order-item {
                        background-color: #fff;
                        justify-content: space-between;
                        background-color: transparent;
                        font-size: 16px;
                        &:nth-child(2n) {
                            background-color: #f4e3eb;
                        }
                        img {
                            width: 40px;
                            height: 40px;
                            border: 3px solid #fff;
                            border-radius: 50%;
                            margin-left: 8px;
                        }
                    }
                }
                &.panel-primary {
                    .panel-heading {
                        background-color: #dcdef4;
                        color: #000;
                    }
                    .order-item {
                        background-color: #fff;
                        justify-content: space-between;
                        background-color: transparent;
                        font-size: 16px;
                        &:nth-child(2n) {
                            background-color: #dcdef4;
                        }
                        img {
                            width: 40px;
                            height: 40px;
                            border: 3px solid #fff;
                            border-radius: 50%;
                            margin-left: 8px;
                        }
                    }
                }
                &.panel-light {
                    .panel-heading {
                        background-color: #e5e6f6;
                        color: #000;
                    }
                    .order-item {
                        background-color: #fff;
                    }
                    .order-number {
                        background-color: #e5e6f6;
                        color: #000;
                        font-size: 16px;
                        width: 34px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 16px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
    @media (max-width: 1024px) {
        .dashboard-welcome {
            h2 {
                font-size: 14px;
            }
            h1 {
                font-size: 16px;
            }
            p {
                display: none;
            }
            
        }
        .statistics {
            .statistics-box:not(:nth-child(2)) {
                order: 3;
            }
            .statistics-box {
                canvas{min-width: 100% ;min-height: 357px;}
                .statistics-panel {
                    .panel-heading {
                        h3 {
                            margin: 0;
                            font-size: 14px;
                        }
                    }
                }
                
                &.full {
                    flex-basis: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .flex-row {
                order: 2;
                flex-direction: row;
                margin-left: 0;
                .box {
                    flex-basis: calc(25% - 18px);
                    margin: 0;
                    margin-left: 24px;
                    text-align: center;
                    &:last-child {
                        margin: 0;
                    }
                    &:nth-child(2) {
                        margin-left: 24px;
                    }
                }
            }
        }
    }
    @media (max-width: 540px) {
        .flow-auto{
            overflow:auto;
        }
        .dashboard-welcome {
           
            img {
                position: absolute;
                left: 32px;
                bottom: 0;
                height: 80px;
            }
        }
        .statistics {
            .statistics-box:not(:nth-child(2)) {
                order: 3;
            }
            .statistics-box {
                flex-basis: 100%;
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;
                .statistics-panel{
                    height: auto;
                    max-height: 457px;
                }
                canvas{min-width: 100% ;min-height: unset;}
                .order-item{
                    font-size: 12px;
                }
                &.h-scroll {
                    order: 2;
                    display: block;
                    width: 100%;
                    white-space: nowrap;
                    overflow-y: hidden;
                    overflow-x: auto;
                    .box {
                        width: 75%;
                        flex-basis: 75%;
                        display: inline-flex;
                        padding: 24px;
                        margin-left: 24px;
                        text-align: center;
                        white-space: normal;
                        &:last-child {
                            margin-left: 0;
                        }
                        h3{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
