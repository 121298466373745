@font-face {
  font-family: Cairo;
  src: url(./assets/fonts/Cairo-Regular.ttf);
  font-weight: 400;
}


@font-face {
  font-family: Cairo;
  src: url(./assets/fonts/Cairo-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: Cairo;
  src: url(./assets/fonts/Cairo-Bold.ttf);
  font-weight: 700;
}

body {
  margin: 0;
  font-family: Cairo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
  background-color: #fff;
}
a:hover,a:focus{
  text-decoration: none;
}
.mb-10{
  margin-bottom: 10px;
}
.orders-btn .svg-inline--fa{
  vertical-align: middle;
}
.btn{
  .svg-inline--fa{
    vertical-align: middle;

  }
}
.container-ms {
  display: block;
  position: relative;
  padding-right: 0;
  margin-bottom: 12px;
  margin-right: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-ms input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-ms:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-ms input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-ms input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-ms .checkmark:after {
  left: 9px;
  top: 8px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg) ;
  -ms-transform: rotate(45deg) ;
  transform: rotate(45deg) ;
}

.item-renderer > span {
  margin-right: 2rem;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.secondary-content  {margin: 0 15px;font-size: 24px;}
.stretch{
  align-items: stretch;
}
.row-direction{
  flex-direction: row;
  display: flex;
}
/* Track */
*{
  scrollbar-color:  #5A62B3 #f1f1f1;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5A62B3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #737ce2;
}
.relative{
  position: relative;
}
.flex-sbetween{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}
.nav{
   flex-wrap: wrap; 
    flex-direction: column;
}
.reverse-alert p{
  flex-direction: row-reverse;

}
.header-filter{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
 .filter-arrow{
   margin-right: 8px;
   transition: .6s;
 }
 .dropdown-menu{
   background-color: #ECEEF9;
   top: -10px !important;
   border: 0;
   width: 100%;
   min-width: unset;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   text-align: right;
   .dropdown-item{
     font-size: 12px;
     color: rgba(18, 20, 50, 0.6);
     font-weight: 400;
     margin-bottom: 16px;
     &:hover,&:focus,&:active{
       font-weight: 600;
       background-color: transparent;
       color: rgba(18, 20, 50, 0.6);
     }
   }
 }
 .dropdown.show{
  .filter-arrow{
    transform: rotate(90deg);
  }
 }
}
.form-toptitle{
  font-size: 20px;
  line-height: 1.618;
  font-weight: 600;
  padding-bottom: 16px;
}
.bottom-border{
  border-bottom: 1px solid rgba(18, 20, 50, 0.16);

}

@media (max-width:768px) {
  .dir-col {
    flex-direction: column;
  } 
}