.btn {
  padding: 5px 16px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 400;
  color: #121432;
  border: 0;
  box-shadow: none !important;
 
  
  &:focus,
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    outline: none;
  }
}
.btn.btn-fav {
  color: #9f9499;
  &:hover,
  &:focus {
    color: #c88ca8;
  }
}
.btn.btn-fav.isfav {
  color: #c88ca8;
  &:hover,
  &:focus {
    color: #9f9499;
  }
}
.modal-content {
  padding: 32px 24px;
  .modal-body {
    padding-top: 32px;
    max-height: calc(100vh - 85px);
    overflow: auto;
  }
}
.presetModal{
  .modal-body{
    max-height: unset;
    overflow: unset;
  }
}
.btn-theme {
  background-color: #5a62b3;
  color: #fff;
  transition: 0.4s;
  box-shadow: none;

  &:focus,
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: #5a62b3;
    color: #fff;
    box-shadow: none;
  }
}
.show > .btn-primary.dropdown-toggle {
  background-color: #dcdef4;
  box-shadow: none;
  color: #121432;
}
.btn-primary {
  background-color: #dcdef4;

  &:focus,
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: #dcdef4;
    box-shadow: none;
    color: #121432;
  }
}

.btn-success {
  background-color: #d4edda;

  &:focus,
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: #d4edda;
    box-shadow: none;
    color: #121432;
  }
}
.btn-info {
  background-color: #9fc9ed;

  &:focus,
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: #9fc9ed;
    box-shadow: none;
    color: #121432;
  }
}

.btn-danger {
  background-color: #f8d7da;

  &:focus,
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: #f8d7da;
    box-shadow: none;
    color: #121432;
  }
}

.app-content {
  background-image: url(./assets/img/Innerpage_background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  max-height: calc(100vh - 30px);
  height: calc(100vh - 30px);
  margin: 15px auto;
  border-radius: 40px;
  padding: 0 9px 24px 9px;
}

.content {
  border-radius: 20px;
  padding: 20px 0;
  color: #121432;
  background-color: #fff;
  height: calc(100vh - 225px);
  overflow: auto;
  width: 100%;
}
.users {
  .collection-item {
    label {
      width: 30%;
    }
  }
}
.collection-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f6f7fb;
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
  .record-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      display: none;
    }
    .btn {
      margin-right: 15px;
      width: auto;
      &:first-child {
        margin: 0;
      }
    }
  }
}
.search-form {
  width: 100%;
  position: relative;
 .search-icon-btn {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(0, -50%);
    color: rgba($color: #5a62b3, $alpha: 0.25);
    cursor: pointer;
    border: 0;
    background-color: transparent;
    outline: 0;
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: none;
  }
  
  .search-input {
    height: 57px;
    border-radius: 20px;
    padding: 16px 24px;
    padding-left: 60px;
    font-size: 15px;
    font-weight: 600;
    line-height: 29px;
    outline: 0;
    box-shadow: none;
    border: 0;
    width: 100%;
  }
}

.page-header {
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;

  .btn {
    width: 100%;
    height: 57px;
  }
}
.form-group {
  label {
    color: #121432;
  }
}
.form-check-label {
  padding-right: 8px;
}
.form-control,
.form-control:disabled {
  padding: 13px 24px;
  font-size: 16px;
  line-height: 1.618;
  font-weight: 400;
  border-radius: 15px;
  background-color: #f6f7fb;
  height: 57px;
  &:focus {
    box-shadow: none;
    background-color: #f6f7fb;
  }
}
textarea {
  width: 100%;
  padding: 16px 24px;
  background-color: #f6f7fb;
  border-radius: 15px;
  border: 0;
  &:focus {
    box-shadow: none;
    background-color: #f6f7fb;
    border: 0;
    outline: 0;
  }
}
.modal {
  .alert {
    position: relative;
    right: 0;
  }
  .modal-content {
    border-radius: 15px;
    position: relative;
    .list-group {
      max-height: 300px;
      overflow: auto;
    }
    .modal-header {
      padding: 0;
      text-align: center;
      border: 0;

      .modal-title {
        font-size: 20px;
        line-height: 37px;
        width: 100%;
      }

      .close {
        position: absolute;
        top: 24px;
        right: 30px;
        padding: 0;
        font-size: 28px;
        &:focus {
          outline: 0;
        }
      }
    }
  }
}
.alert {
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
  right: 15px;
  z-index: 999;
}

.close:focus {
  outline: 0;
}
.permissions {
  .list-group-item {
    background-color: #f6f7fb;
    border: 0;
    border-radius: 15px;
    margin: 8px auto;
    padding: 20px 24px;
    width: 100%;
    font-size: 16px;
    color: rgba(55, 62, 160, 0.5);
    text-align: right;
    label {
      margin-right: 8px;
    }
  }
}
form {
  h3 {
    font-size: 16px;
    line-height: 37px;
    margin: 16px auto;
    text-align: right;
    font-weight: 600;
  }
}
.search-btn {
  display: none;
}
.mobile-search{
  justify-content: flex-end;
  position: relative;
  display: flex;
  .search-form {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 1;
    border: 1px solid rgba(162, 162, 162, 0.514);
    border-radius: 15px;
    background-color: #fff;
    width: 70vw;
    
  }
  .shake{
    animation: shake .6s;
  }
  .search-btn {
  display: block;
    color: #5a62b3;
    background: #fff;
    width: 57px;
}
}
.tv {
  display: none;
}

div.file {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 15px;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  input[type="file"] {
    position: absolute;
    width: 100%;
    cursor: pointer;

    height: 100%;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
  }
}
.cursor {
  cursor: pointer;
}

.buffet-wrapper {
  background-color: #fff;
  padding: 32px;
  height: calc(100vh - 225px);
  overflow: auto;
  border-radius: 15px;
  position: relative;
  .title {
    font-size: 20px;
    line-height: 37px;
    margin-bottom: 24px;
  }
}
.hasPagination.buffet-wrapper{
  height: calc(100vh - 265px);

}
//sitesettings

.sitesettings {
  .collections {
    border-top: 1px solid #cbcee7;
    border-radius: 0;
    padding: 0;
    .collection-item {
      padding: 12px 0;
      label {
        width: 40%;
        display: inline-block;
      }
      span {
        display: inline-block;
        width: 30%;
      }
      .record-control {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        .btn {
          margin: 0;
        }
      }
    }
  }
}

//sitesettings

//points
.points-container {
  height: 56px;
  border-radius: 15px;
  background-image: url(../src/assets/img/points.svg);
  background-color: #faf8fb;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 8px;
  overflow: hidden;
  h6 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-left: 8px;
  }
  span {
    font-size: 10px;
  }
  label {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 8px;
  }
}
//points

// Profile page
.profile {
  .collections {
    padding: 24px 0;
    padding-bottom: 0;
    .collection-item {
      padding: 12px 0;
      label,
      .record-control {
        width: 70%;
        display: inline-block;
      }
      .record-control {
        .btn {
          margin: 0;
        }
      }
      span {
        display: inline-block;
        width: 30%;
      }
    }
  }
}
.profile-img-container {
  width: 173px;
  height: 173px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  .profile-img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
  .file {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: rgba($color: #000, $alpha: 0.25);
    font-size: 25px;
    cursor: pointer;
  }
}

// Profile page

//items
.item-card {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #faf8fb;
  border-radius: 15px;
  padding: 24px 30px;
  margin-bottom: 30px;
  width: 100%;
  h4 {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .record-control {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    .btn {
      margin-right: 15px;
      width: auto;
      padding: 5px 32px;
      border-radius: 15px;
      &:first-child {
        margin-right: 0;
      }
    }
  }
  .item-quantity {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    img {
      position: absolute;
      width: 85%;
      height: 85%;
      top: 7.5%;
      left: 7.5%;
      border-radius: 50%;
    }
  }
}

//items
img {
  object-fit: cover;
}
//presets
.preset-item:not(:last-child)::after {
  content: " - ";
}
.presets {
  .item-card {
    height: auto;
    .item-data {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .preset-img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 12px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .preset-info {
        width: calc(100% - 102px);
        h4 {
          text-align: right;
        }
        h5 {
          font-size: 14px;
          font-weight: 600;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          &:not(:last-child):after {
            content: " - ";
          }
        }
      }
    }
  }
}
select {
  background-image: url(../src/assets/img/arrow.svg);
  background-repeat: no-repeat, repeat;
  background-position: left 34px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:focus {
    outline: 0;
  }
}
select::-ms-expand {
  display: none;
}
.select-form {
  display: flex;
  align-items: center;
  padding: unset;
  .select__control {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    border: 0;
    padding: 13px 24px;
    .select__indicator {
      cursor: pointer;
    }
    .select__dropdown-indicator {
      svg {
        width: 17px;
        fill: #121432;
      }
    }
    .select__value-container > div {
      width: auto !important;
    }
    .select__value-container {
      padding: 0;
      .select__multi-value {
        height: 29px;
        background-color: #dbdbdb;
        border-radius: 15px;
        padding: 5px 10px;
        width: auto;
        font-size: 14px;
        font-weight: 400;
        .select__multi-value__label {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

//presets

//AddToCart
.AddToCart {
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span.circle {
      width: 127px;
      height: 127px;
      border-radius: 50%;
      color: #121432;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 8px;
      span {
        font-size: 54px;
        font-weight: 700;
      }
      small {
        font-size: 12px;
        font-weight: 600;
        color: rgba(18, 20, 50, 0.5);
      }
    }
  }
  .order-item-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 16px;
    color: rgba(18, 20, 50, 0.5);
    .item-points {
      color: #000;
    }
    .name {
      width: 50%;
      padding-left: 16px;
      span {
        background-color: #f6f7fb;
        border-radius: 15px;
        padding: 9px 24px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: 16px;
        line-height: 37px;
        small {
          font-size: 13px;
        }
      }
    }
    .qty {
      width: 30%;
      select {
        width: calc(50% - 8px);
        margin-left: 8px;
        height: 100%;
        padding: 8px;
        border-radius: 15px;
        border: 0;
        background-color: #f6f7fb;
        background-position: left 8px top 50%, 0 0;
        color: rgba(18, 20, 50, 0.5);
      }
    }
    .stock {
      width: 20%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .price-choice {
    font-size: 8px;
    font-weight: 400;
    color: rgba(18, 20, 50, 0.5);
  }

  .modal-body-title {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    h4 {
      width: 80%;
      text-align: right;
      font-size: 18px;
      font-weight: 600;
    }
    h6 {
      width: 20%;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .form-group {
    text-align: right;
  }
  label {
    font-size: 18px;
    font-weight: 600;
  }
}

//AddToCart

//cart page
.cart-row.noitems {
  color: rgba(18, 20, 50, 0.5) !important;
  justify-content: center !important;
  font-weight: 700;
  font-size: 14px;
}
.cart {
  .checkout-btns {
    display: flex;
    padding-top: 16px;
    border-top: 1px solid #ebedf3;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    .btn {
      margin-left: 16px;
      &:last-child {
        margin-left: 0;
      }
    }
  }
  .cart-content {
    .cart-row {
      padding: 27px 24px;
      border-radius: 15px;
      background-color: #faf8fb;
      margin-bottom: 16px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: #121432;
      &:last-child {
        margin-bottom: 24px;
      }
      .item-name {
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        .cartItem-img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin-left: 16px;
        }
      }
      .item-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        .item-price {
          font-size: 12px;
          font-weight: 600;
          line-height: 23px;
        }
        .cart-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          .cart-btn {
            font-size: 17px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

//cart page
.notifications {
  .order-btns {
    .btn {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .notification-delete {
      color: #000;
    }
  }
}
// order history
.order-history,
.notifications {
  .order-row,
  .notification-row {
    padding: 27px 24px;
    border-radius: 15px;
    background-color: #faf8fb;
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: #121432;
    &:last-child {
      margin-bottom: 24px;
    }
    .order-info {
      h4 {
        font-size: 14px;
        font-weight: 700;
        .order-status {
          padding: 5px 16px;
          border-radius: 7px;
          font-size: 10px;

          color: #fff;
          font-weight: 400;
          margin-right: 16px;
        }
        .Pending {
          background-color: #f5c86d;
        }
        .InProgress {
          background-color: #7f86d6;
        }
        .Cancelled {
          background-color: #000;
        }
        .Completed {
          background-color: #81dea3;
        }
        .Rejected {
          background-color: #e93e3e;
        }
      }

      .order-date {
        font-size: 10px;
        font-weight: 400;
      }
    }

    .order-btns {
      display: flex;
      justify-self: flex-end;
      justify-content: center;
      align-items: center;
      .btn{
        display: flex;
        align-items: center;
      }
      p {
        padding: 0 15px;
        margin: 0;
        font-size: 12px;
      }
      .rating {
        padding: 0 16px;
        span {
          img {
            width: 12px;
            height: 12px;
            margin: 0 3px;
          }
        }
      }
      .btn {
        margin-left: 8px;
        font-size: 12px;
        padding: 10px 16px;
        line-height: 23px;
        .order-icon {
          color: #8f91aa;
          margin-left: 8px;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
// order history
//rating-stars
.rating-stars {
  display: flex;
  width: 100%;
  text-align: center;
  >span {
    margin: 0 3px;
    
  }
  .full-star,.empty-star{
    font-size: 35px;
  }
}
//rating-stars
//reasons
.reasons-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .reason {
    background-color: #dcdef4;
    border-radius: 15px;
    padding: 5px 16px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 16px;
    cursor: pointer;
    transition: 0.6s;
  }
  .activeReason {
    background-color: #fd6b6b;
    color: #fff;
  }
}

//reasons

//employee Items
.employeeItems .list-item {
  background: #faf8fb;
  border-radius: 15px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .user_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
    img {
      border-radius: 50%;
      width: 56px;
      height: 56px;
      margin-left: 16px;
    }
  }
  h4 {
    font-size: 14px;
    color: #121432;
    font-weight: bold;
    margin: 0;
  }

  .record-controls {
    button:first-child {
      margin-left: 8px;
    }
  }
}
//Admin history order
.adminHistory {
  .keyword-form {
    flex-basis: 30%;
  }
  .search-input {
    background-color: #f6f7fc;
    border-radius: 10px;
    height: 40px;
  }
  .header-filter .dropdown-menu {
    width: auto;
    min-width: 100%;
  }
  .dropdown {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    padding: 0 16px;
    button.dropdown-toggle {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      span{
        display: inline-block;
        width: calc(100% - 18px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .datepicker-dropdown .dropdown-menu {
    padding: 0;
  }
  //date picker

  .react-datepicker__header {
    background-color: #dcdef4;
  }

  .react-datepicker__day--in-range {
    background-color: #ebccdb;
    color: #5a62b3;
    &:hover {
      background-color: #ebccdb;
    }
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #5a62b3;
    color: #ebccdb;
    &:hover {
      background-color: #5a62b3;
    }
  }
  .react-datepicker {
    background-color: #eceef9;
    border: 0;
  }
  //date picker
}
//Admin history order

//myItems
.myItems {
  display: flex;
  .list-item {
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    margin-left: 24px;
    margin-bottom: 24px;
    background-color: #faf8fb;
    border-radius: 15px;
    padding: 24px 0;
    justify-content: center;
    align-items: center;
    height: 140px;
    &:nth-child(5n) {
      margin-left: 0;
    }
    .item-name {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .item-text {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 8px;
    }
    .item-quantity {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

#preloader {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.custom-checkbox {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 50%;
    left: auto;
    height: 25px;
    width: 25px;
    background-color: #eee;
    right: 50%;
    transform: translate(12px, -12.5px);
    border-radius: 5px;
  }
  input:checked ~ .checkmark {
    background-color: #d4edda;
  }
  .checkmark:after {
    left: 9px;
    top: 6px;
    width: 6px;
    height: 10px;
    border: solid #559163;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}

svg#freepik_stories-403-error-forbidden:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-403-error-forbidden.animated #freepik--error-403-sign--inject-107 {
  animation: 1.5s Infinite linear shake;
  animation-delay: 0s;
}

@media print {
  .print-qr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.pagination {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  animation: openCenter 1s;
  margin: auto;
  overflow: auto;
  li {
    margin: 0 5px;
  }
  .btn-primary{
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
  .active {
    .btn-primary {
      background-color: #5b62b3;
      color: #fff;
    }
  }
  .disabled-page {
    cursor: not-allowed;
    opacity: 0.4;
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: #dcdef4;
    color: #000;
  }
}
@keyframes openCenter {
  0% {
    opacity: 0;
    width: 0;
    overflow: hidden;
    transform: scale(0.8);
  }
  50% {
    width: 100%;
    overflow: visible;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .app-content {
    margin: 0;
  }
  .adminHistory {
    .header-filter {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    .dropdown {
      flex-basis: 25%;
      max-width: 25%;
      margin-bottom: 8px;
    }
    .keyword-form {
      flex-basis: 100%;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 767px) {
  .order-history {
    .order-row {
      flex-direction: column;
      .order-btns {
        .btn {
          padding: 10px 12px;
        }
      }
      .order-info {
        width: 100%;
        h4 {
          display: flex;
          justify-content: space-between;
        }
        margin-bottom: 16px;
      }
    }
  }
  .AddToCart {
    .order-item-info {
      display: flex;
      flex-wrap: wrap;

      .name {
        width: 80%;
      }
      .qty {
        width: 100%;
        order: 3;
        margin-top: 16px;
      }
      .stock {
        width: 20%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .cart-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item-controls {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 16px;

      .cart-btns {
        margin: 0;
        margin-top: 16px;
      }
    }
  }
  .users .collection-item label {
    width: 100%;
  }
  .profile {
    .collections {
      .collection-item {
        span,
        label,
        .record-control {
          width: 100%;
        }
        .record-control {
          justify-content: center;
        }
      }
    }
  }
  .collection-item {
    flex-direction: column;
    align-items: flex-start;
    .record-control {
      width: 100%;
      margin-top: 8px;
      justify-content: center;
    }
  }
  .employeeItems {
    .list-item {
      flex-direction: column;
      .user_info {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .myItems {
    display: flex;
    flex-direction: column;
    .list-item {
      flex-basis: 100%;
    }
  }
  .modal {
    .modal-dialog {
      margin: 0;
    }
    .modal-content {
      padding: 32px 0px;
      border-radius: 0;
      height: 100vh;
      .modal-header {
        padding-top: 24px;
        .close {
          position: absolute;
          top: 24px;
          right: 50%;
          padding: 0;
          font-size: 50px;
          font-family: auto;

          &:focus {
            outline: 0;
          }
        }
      }
    }
  }
  .sitesettings {
    .collections {
      .collection-item {
        span,
        label,
        .record-control {
          width: 100%;
        }
        .record-control {
          justify-content: center;
        }
      }
    }
  }
  .adminHistory {
    .header-filter {
      .dropdown {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
      
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .tv {
    display: block;
  }
 
  .myItems {
    display: flex;
    .list-item {
      flex-basis: 25%;
    }
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 600px;
  }
}

@keyframes enterAnimation {
  from {
    transform: translateX(200px);
  }
  to {
    transform: translateX(0);
  }
}
.alert-dismissible{
  padding-right:  1.25rem;
  padding-left: 4rem;
  .close{
    left: 0;
    right: auto;
    line-height: .8;
  }
}
.full-star{
  color: #444762;
  font-size: 14px;
}
.empty-star{
  color: #DCDEF4;
  font-size: 14px;

}